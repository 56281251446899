import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import backArrow from 'assets/images/activation/back-arrow.svg';
import { mixpanelReset } from 'utils/mixpanelHelpers';
import { Desktop, Mobile } from 'utils/responsive';
import { currentRole } from 'utils/role';
import Icon from '../Icon';
import { navConfig } from './navConfig';
import NavLinkList from './NavLinkList';
import NavLogo from './NavLogo';
import NavSidebar from './NavSidebar';
import styles from './styles.module';
import ReferACoWorkerModal from 'portal/components/ReferACoWorkerModal';
import { showEmployeeDashboard } from 'employee-pro-shared/constants';
import BravelyData from 'utils/data';
import ChevronLeft from 'pro/components/Icons/ChevronLeft';
class Nav extends React.Component {
  static propTypes = {
    showBackButton: PropTypes.bool,
    disableBackButton: PropTypes.bool,
    onClickBackArrow: PropTypes.func,
    hideNavLinks: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      showReferralModal: false,
    };
  }

  setShowReferralModal(val) {
    this.setState({ showReferralModal: val });
  }

  componentDidMount() {
    const params = new URL(document.location).searchParams;
    const refer = params.get('refer');

    if (refer) this.setShowReferralModal(true);

    const referralCTA = document.getElementById('refer-a-co-worker');
    referralCTA &&
      referralCTA.addEventListener('click', () =>
        this.setShowReferralModal(true)
      );
  }

  renderBackArrow() {
    const { showBackButton } = this.props;
    const icon = <Icon icon={backArrow} />;
    const chevronIcon = (
      <ChevronLeft fill={'#AD72B0'} className={styles.iconStyle} />
    );

    if (!showBackButton) {
      // first screen, so do not render back arrow
      return null;
    }

    if (this.props.disableBackButton) {
      return <span className={styles.mobileNavBack}>{icon}</span>;
    }

    return (
      <button
        className={cx(styles.mobileNavBack, {
          [styles.dashboardStyleBackButton]: showEmployeeDashboard,
        })}
        onClick={this.props.onClickBackArrow}
      >
        {chevronIcon}
        <div>Back</div>
      </button>
    );
  }

  getLabelForCurrentPath() {
    const sections = BravelyData('navigation').links;
    const pathname = new URL(document.location).pathname;
    for (const section in sections) {
      const props = sections[section];
      if (pathname.startsWith(props.url)) return props.label;
    }

    return null;
  }

  render() {
    // Passing an onClick callback
    // to reset all Mixpanel properties on logout
    const config = navConfig({
      logout: mixpanelReset,
    });

    return (
      config.disable !== true && (
        <div
          className={cx(
            'shared-react-nav',
            styles.navContainer,
            styles[config.theme],
            styles[currentRole()]
          )}
        >
          {!showEmployeeDashboard && (
            <Desktop>
              <div className={styles.navWrapperDesktop}>
                <NavLogo url={config.logoUrl} />
                {this.props.hideNavLinks ? null : (
                  <NavLinkList navConfig={config} />
                )}
              </div>
            </Desktop>
          )}
          <Mobile>
            <div
              className={cx(styles.navWrapperMobile, {
                [styles.navWrapperDashboard]: showEmployeeDashboard,
              })}
            >
              {this.renderBackArrow()}
              {showEmployeeDashboard ? (
                <div className={styles.navLabel}>
                  {this.getLabelForCurrentPath() ? (
                    this.getLabelForCurrentPath()
                  ) : (
                    <NavLogo url={config.logoUrl} />
                  )}
                </div>
              ) : (
                <NavLogo url={config.logoUrl} />
              )}
              <NavSidebar />
            </div>
          </Mobile>
          <ReferACoWorkerModal
            showModal={this.state.showReferralModal}
            setShowModal={() => this.setShowReferralModal()}
          />
        </div>
      )
    );
  }
}

export default Nav;
