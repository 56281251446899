import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import styles from './styles.module';
import Icon from '../../../shared/components/Icon';

function renderIcon(icon, iconClassName) {
  if (icon && iconClassName) {
    return <Icon icon={icon} className={iconClassName} />;
  }
}

const NavDropdown = ({
  label,
  className = null,
  icon = null,
  iconClassName = null,
  children = {},
}) => {
  const dropdownRef = useRef();
  const [open, setOpen] = useState(false);
  const handleClickOutside = (event, open) => {
    if (open && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('click', (event) => {
        handleClickOutside(event, open);
      });
    } else {
      document.removeEventListener('click', (event) => {
        handleClickOutside(event, open);
      });
    }

    return () => {
      document.removeEventListener('click', (event) => {
        handleClickOutside(event, open);
      });
    };
  }, [open]);

  return (
    <div
      ref={dropdownRef}
      className={cx(styles.navLink, styles.navDropdown, {
        [className]: !!className === true,
        [styles.navDropdownOpen]: open === true,
      })}
      onClick={() => {
        setOpen(!open);
      }}
    >
      {renderIcon(icon, iconClassName)}
      <div className={styles.navDropdownLabel}>{label}</div>
      <div className={styles.navDropdownMenu}>{children}</div>
    </div>
  );
};

export default NavDropdown;
