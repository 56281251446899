import React from 'react';

const SVG = ({
  style = {},
  fill = '#000',
  width = 16,
  className = '',
  viewBox = '0 0 10 16',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <polygon fill={fill} points="9.6,1.6 8,0 0,8 8,16 9.6,14.4 3.2,8 " />
  </svg>
);

export default SVG;
