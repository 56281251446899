import React from 'react';
import cx from 'classnames';

import { currentRole } from 'utils/role';
import appLogo from 'assets/images/logo-full.png';
import proLogo from 'assets/images/pro-logo.svg'; // Importing the SVG so it can be loaded this way and loaded inline from Rails
import commandLogo from 'assets/images/command-logo.svg'; // Importing the SVG so it can be loaded this way and loaded inline from Rails
import styles from './styles';

const LOGO = {
  anonymous: <img src={appLogo} className={styles.navLogoImage} />,
  employee: <img src={appLogo} className={styles.navLogoImage} />,
  organization: <img src={appLogo} className={styles.navLogoImage} />,
  pro: <div dangerouslySetInnerHTML={{ __html: proLogo }} />,
  admin: <div dangerouslySetInnerHTML={{ __html: commandLogo }} />,
};

const CLASS_NAME = {
  anonymous: cx(styles.navLogo, styles.navLogoAnonymous),
  employee: cx(styles.navLogo, styles.navLogoEmployee),
  organization: cx(styles.navLogo, styles.navLogoOrganization),
  pro: cx(styles.navLogo, styles.navLogoPro),
  admin: cx(styles.navLogo, styles.navLogoCommand),
};

const NavLogo = ({ url }) => {
  const role = currentRole();

  return (
    <a href={`${url}?logo`} className={CLASS_NAME[role]}>
      {LOGO[role]}
    </a>
  );
};

export default NavLogo;
