import React from 'react';
import cx from 'classnames';
import styles from './styles.module';
import Icon from '../../../shared/components/Icon';
import { useTranslation } from 'react-i18next';
import { mixpanelTrack, mixpanelEvents } from 'utils/mixpanelHelpers';

const {
  general: { CLICK_HOME_LINK },
} = mixpanelEvents;

function renderIcon(icon, iconClassName) {
  if (icon && iconClassName) {
    return <Icon icon={icon} className={iconClassName} />;
  }
}

const NavLink = ({
  label,
  url = null,
  target = null,
  icon = null,
  onClick = null,
  className = null,
  iconClassName = null,
  id,
}) => {
  let isActive = window.location.pathname == url;
  const { t } = useTranslation();

  const handleClick = () => {
    if (label === 'Home') {
      mixpanelTrack(CLICK_HOME_LINK);
    }

    onClick && onClick();
  };

  return (
    <a
      className={cx(styles.navLink, {
        [className]: !!className === true,
        [styles.selected]: isActive,
      })}
      href={url}
      target={target}
      onClick={handleClick}
      id={id}
    >
      {renderIcon(icon, iconClassName)}
      {t(label)}
    </a>
  );
};

export default NavLink;
