export const init = ({
  height,
  width,
  padding,
  overlayOverrides = {},
  ...contentOverrides
}) => ({
  content: {
    height: height || '95vh',
    width: width || '784px',
    borderRadius: '4px',
    alignSelf: 'center',
    position: 'unset',
    padding: padding || '0px',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'default',
    ...contentOverrides,
  },
  overlay: {
    background: 'rgba(24,37,58, .8)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    overflowY: 'scroll',
    cursor: 'pointer',
    ...overlayOverrides,
  },
});
