import React from 'react';

// Dependencies
import cx from 'classnames';

// Shared Components
import Icon from 'shared/components/Icon';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import FlashMessage from 'shared/components/FlashMessage';
import PrimaryButton from 'shared/components/PrimaryButton';

// Icons
import ReferralIcon from 'assets/images/portal/dialog.svg';

// Helpers
import { MSG_CHAR_LIMIT } from 'portal/components/ReferACoWorkerModal/helpers';

const Body = ({
  emailFocus: focus,
  setEmailFocus,
  msg,
  setMsg,
  setEmail,
  email = '',
  emailValidationMessage,
  msgValidationMessage,
  validate,
  t = (val) => val,
  btnText,
  isSubmitting,
  resetButton,
  serverError,
}) => {
  const emailError = Boolean(emailValidationMessage);
  const msgError = Boolean(msgValidationMessage);
  const msgExceedsLimit = msg.length > MSG_CHAR_LIMIT;
  const error = emailError || msgError;
  const blankEmail = email.trim().length === 0;
  const disabled = blankEmail || error;
  const msgSent = btnText === 'Sent!';

  return (
    <div className="refer-a-co-worker-body">
      <div>
        <Icon icon={ReferralIcon} />
      </div>
      <div>
        <div className="title">{t('Tell your coworkers about Bravely')}</div>
        <div className="sub-title">
          {t('Help a coworker get started with professional coaching.')}
        </div>
        <label>
          {t('Enter the email address of a user in your organization')}
        </label>
        <div className={cx('email-control', { focus, error: emailError })}>
          <input
            type="email"
            placeholder="coworker@yourorg.com"
            onFocus={() => setEmailFocus(true)}
            onBlur={() => setEmailFocus(false)}
            onChange={(e) => {
              setEmail(e.target.value);
              resetButton();
            }}
            value={email}
            autoFocus
          />
        </div>
        <div className="error-msg">{emailValidationMessage}</div>
        <label>{t('Enter a message for your coworker')}</label>
        <textarea
          className={cx('note', { error: msgError })}
          value={msg}
          onChange={(e) => {
            setMsg(e.target.value);
            resetButton();
          }}
        />
        <div className={cx('char-count', { error: msgExceedsLimit })}>
          {msg.length}/{MSG_CHAR_LIMIT}
        </div>
        <div className="error-msg">{msgValidationMessage}</div>
        <PrimaryButton
          className={cx('send-btn', { sent: msgSent })}
          disabled={disabled}
          onClick={() => validate()}
        >
          {isSubmitting ? <LoadingSpinner /> : t(btnText)}
        </PrimaryButton>
      </div>
      {serverError && (
        <FlashMessage>Something went wrong, please try again</FlashMessage>
      )}
    </div>
  );
};

export default Body;
