import React from 'react';
import NavLink from './NavLink';
import styles from './styles.module';
import NavDivider from './NavDivider';
import NavDropdown from './NavDropdown';

function prepareLinkList(links) {
  const linkList = [];
  links.forEach((link) => {
    if (link.dropdown) {
      linkList.push(
        <NavDropdown key={link.key} label={link.label}>
          {prepareLinkList(link.links)}
        </NavDropdown>
      );
    } else if (link.divider) {
      linkList.push(<NavDivider {...link} />);
    } else {
      linkList.push(<NavLink {...link} className={styles.navLink} />);
    }
  });
  return linkList;
}

const NavLinkList = ({ navConfig, navStyles }) => (
  <div className={[styles.navLinkContainer, navStyles].join(' ')}>
    {prepareLinkList(navConfig.links)}
  </div>
);

export default NavLinkList;
