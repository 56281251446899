export const REGEX = {
  VALID_FULL_NAME: /^(([A-Za-z]+[\-\']?)*([A-Za-z]+)?\s)+([A-Za-z]+[\-\']?)*([A-Za-z]+)?$/,
  VALID_SINGLE_NAME: /^(?=.{1,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/,
  VALID_EMAIL_ADDRESS: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
const VALIDATION_MESSAGES = {
  CANNOT_BE_EMPTY: (val) => `${val} cannot be empty`,
  MUST_BE_VALID: (val, example) => `${val} must be valid. E.g ${example}`,
  CANNOT_HAVE_TRAILING: (val, trailingChars) =>
    `${val} cannot have trailing "${trailingChars}"`,
};
export const nameValidation = (value = '', label = '') => {
  // need ? to protect against null values =)
  const val = value?.trim();
  const errors = [];
  if (!Boolean(val)) errors.push(VALIDATION_MESSAGES.CANNOT_BE_EMPTY(label));
  if (!val.match(REGEX.VALID_FULL_NAME))
    errors.push(VALIDATION_MESSAGES.MUST_BE_VALID(label, 'John Doe'));

  return { valid: errors.length === 0, errors };
};

export const preferredNameValidation = (value = '', label = '') => {
  // need ? to protect against null values =)
  const val = value?.trim();
  const errors = [];

  if (Boolean(val)) {
    if (!val.match(REGEX.VALID_SINGLE_NAME))
      errors.push(VALIDATION_MESSAGES.MUST_BE_VALID(label, 'John'));
  }

  return { valid: errors.length === 0, errors };
};

export const pronounsValidation = (value = '') => {
  // need ? to protect against null values =)
  const val = value?.trim();
  const errors = [];
  if (!val?.length) {
    return { valid: true, errors };
  }
  if (val[val?.length - 1] == '/') {
    errors.push(VALIDATION_MESSAGES.CANNOT_HAVE_TRAILING('Pronouns', '/'));
    return { valid: errors.length === 0, errors };
  }
  const pronounItems = value.split('/');
  pronounItems.forEach((item) => {
    if (
      !item.trim().match(REGEX.VALID_SINGLE_NAME) ||
      item.trim().split(' ').length > 1
    ) {
      errors.push(
        VALIDATION_MESSAGES.MUST_BE_VALID('Pronouns', 'he/him or she/her')
      );
      return { valid: errors.length === 0, errors };
    }
    const pronounItems = value.split('/');
    pronounItems.forEach((item) => {
      if (
        !item.trim().match(REGEX.VALID_SINGLE_NAME) ||
        item.trim().split(' ').length > 1
      ) {
        errors.push(
          VALIDATION_MESSAGES.MUST_BE_VALID('Pronouns', 'he/him or she/her')
        );
        return { valid: errors.length === 0, errors };
      }
    });
  });

  return { valid: errors.length === 0, errors };
};

export const titleValidation = (value = '') => {
  const val = value?.trim();
  const errors = [];
  if (!!val && !val.match(REGEX.VALID_SINGLE_NAME)) {
    errors.push(VALIDATION_MESSAGES.MUST_BE_VALID(val, 'Product Manager'));
  }

  return { valid: errors.length === 0, errors };
};

// Validating various context state variables with respect to each page
export const aboutPageValidation = (context = {}) => {
  const {
    fullName = '',
    preferredName = '',
    pronouns = '',
    title = '',
    setValidation,
  } = context;

  const {
    valid: fullNameIsValid,
    errors: fullNameValidationErrors,
  } = nameValidation(fullName, 'Full Name');

  const {
    valid: preferredNameIsValid,
    errors: preferredNameValidationErrors,
  } = preferredNameValidation(preferredName, 'Preferred Name');

  const {
    valid: pronounsAreValid,
    errors: pronounsValidationErrors,
  } = pronounsValidation(pronouns);

  const {
    valid: titleIsValid,
    errors: titleValidationErrors,
  } = titleValidation(title);

  const nextButtonEnabled =
    fullNameIsValid && preferredNameIsValid && pronounsAreValid && titleIsValid;

  const next = nextButtonEnabled ? 'demography' : undefined;

  const errors = {
    fullName: fullNameValidationErrors,
    preferredName: preferredNameValidationErrors,
    pronouns: pronounsValidationErrors,
    title: titleValidationErrors,
  };

  setValidation({ next, back: undefined, errors });
};
