import { mixpanelEvents, mixpanelTrack } from './mixpanelHelpers';

const {
  general: { OPEN_INTERCOM },
} = mixpanelEvents;

export default function showIntercom() {
  mixpanelTrack(OPEN_INTERCOM);

  window.Intercom && window.Intercom('show');
}
