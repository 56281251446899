import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import './styles.scss';
import { init } from './styles';
import Icon from 'shared/components/Icon';
import CloseIcon from 'command/assets/x.svg';
import cx from 'classnames';

const Modal = ({
  show,
  title,
  closeFunction,
  styleOverrides = {},
  bodyText,
  disableActionBtn,
  confirmationCallback,
  confirmBtnLabel,
  cancelBtnLabel,
  showCancelButton = true,
  showConfirmButton = true,
  bodyComponent,
  headerComponent,
  footerComponent,
  showTopDivider = true,
  showFooter = true,
}) => {
  useEffect(() => {
    ReactModal.setAppElement('body');
  }, []);

  const body = bodyComponent || (
    <div className="custom-modal-body">
      <label>{bodyText}</label>
    </div>
  );

  const footer = footerComponent || (
    <div>
      {showConfirmButton && (
        <button
          className="action"
          disabled={disableActionBtn}
          onClick={confirmationCallback}
        >
          {confirmBtnLabel || 'OK'}
        </button>
      )}
      {showCancelButton && (
        <button className="dismiss" onClick={closeFunction}>
          {cancelBtnLabel || 'Dismiss'}
        </button>
      )}
    </div>
  );

  const header = headerComponent || <label>{title}</label>;

  return (
    <ReactModal
      isOpen={show}
      contentLabel="Bravely"
      style={init(styleOverrides)}
      shouldCloseOnOverlayClick={true}
      closeTimeoutMS={500}
    >
      <div
        className={cx('custom-modal-header', { hideDivider: !showTopDivider })}
      >
        {header}
        <button onClick={() => closeFunction()} className="close-modal-btn">
          <Icon icon={CloseIcon} className="icon" />
        </button>
      </div>
      {body}
      {showFooter && <div className="custom-modal-footer">{footer}</div>}
    </ReactModal>
  );
};

export default Modal;
