import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import showIntercom from '../../../../utils/showIntercom';

import styles from './styles.module';
import NavLinkList from './NavLinkList';
import { navConfig } from './navConfig';
import { showEmployeeDashboard } from 'employee-pro-shared/constants';

class NavSidebar extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  // This keeps state in sync with the default ways of opening/closing of the menu:
  // clicking the X, pressing the ESC key, clicking outside of the sidebar, etc.
  toggleSidebar = (menuOpen) => {
    this.setState({ menuOpen: !menuOpen });
  };

  hideSidebar = () => {
    this.setState({ menuOpen: false });
  };

  // Hijack :support navLink
  onClickSupport(event) {
    event?.preventDefault();
    showIntercom();
    this.hideSidebar();
    return false;
  }

  render() {
    const { className } = this.props;
    const config = navConfig({
      support: this.onClickSupport.bind(this),
    });

    return (
      !!config.links.length && (
        <>
          <div
            className={cx(styles.sidebarGlass, {
              [styles.sidebarGlassOpen]: this.state.menuOpen,
            })}
            onClick={this.hideSidebar}
          />
          <div
            className={cx(styles.sidebarPanel, {
              [styles.sidebarPanelOpen]: this.state.menuOpen,
            })}
          >
            <NavLinkList
              navConfig={config}
              navStyles={styles.sidebarNavLinkList}
            />
          </div>
          <div
            className={cx(styles.sidebarBurger, {
              [styles.sidebarBurgerPanelOpen]: this.state.menuOpen,
              [styles.sidebarBurgerDashboard]: showEmployeeDashboard,
              [styles[className]]: className && !this.state.menuOpen,
            })}
            onClick={() => {
              this.toggleSidebar(this.state.menuOpen);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 50"
              className={styles.sidebarBurgerSvg}
            >
              {this.state.menuOpen ? (
                <path d="M33.08 12.85l4.08 4.08L29.08 25l8.04 8.04-4.08 4.08L25 29.08l-8.08 8.08-4.08-4.08L20.92 25l-8.04-8.04 4.08-4.08L25 20.92l8.08-8.07z" />
              ) : (
                <path d="M39.25 22.48v5.77h-28.5v-5.77h28.5zM39.25 12.12v5.77h-28.5v-5.77h28.5zM39.25 32.85v5.77h-28.5v-5.77h28.5z" />
              )}
            </svg>
          </div>
        </>
      )
    );
  }
}

export default NavSidebar;
