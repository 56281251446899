import BravelyData from 'utils/data';

export const getCurrentPath = (url = '') => {
  if (!url || typeof url !== 'string') {
    return '';
  }

  const pathIndex = url.charAt(url.length - 1) === '/' ? 2 : 1;
  const paths = url.split('/');
  const path = paths[paths.length - pathIndex];
  return path;
};

export const getAllRoutes = ({ routes = {}, baseStr = '', results = [] }) => {
  const keys = Object.keys(routes);
  const size = keys.length;
  let x = 0;

  while (x < size) {
    const key = keys[x];
    const route = routes[key];
    const nestedRoutes = route?.routes;
    if (nestedRoutes) {
      const parent = baseStr;
      baseStr += '/' + route.value;
      getAllRoutes({ routes: nestedRoutes, baseStr, results });
      baseStr = parent;
    }
    if (route?.value) {
      const val = baseStr + '/' + route.value;
      results.push(val);
    }

    x += 1;
  }

  return results;
};

// A recursive function that makes a map of route keys, even if they are nested.
export const createRoutesMap = (
  routes = {},
  parentIdx = 0,
  parentKey,
  parentSlug
) => {
  const keys = Object.keys(routes);
  return keys.reduce((acc, curr, idx) => {
    const currentRoute = routes[curr] || {};
    const { caption, routes: nestedRoutes = {}, value, component } =
      currentRoute || {};
    const nested = Boolean(Object.keys(nestedRoutes).length);
    const isLastRoute = idx === keys.length - 1;
    if (nested) {
      acc = {
        ...acc,
        ...createRoutesMap(currentRoute.routes, idx, curr, currentRoute.value),
      };
    }
    if (Boolean(currentRoute.value)) {
      acc[currentRoute.value] = {
        value,
        idx,
        parentIdx,
        isLastRoute,
        nested,
        parentKey,
        parentSlug,
        caption,
        routes: nestedRoutes,
        component,
        key: curr,
      };
    }
    return acc;
  }, {});
};

export const parseNavigation = (path = '', map = {}, setNavigation) => {
  const route = getCurrentPath(path);
  const currentRoute = map[route] || {};
  const { idx, isLastRoute, parentIdx, parentSlug } = currentRoute;
  const parentRoute = map[parentSlug];
  const peerRoutes = parentRoute?.routes || [];
  // If route has nested/child routes
  if (currentRoute.nested) {
    const previousRoute = peerRoutes[idx - 1];
    const backCaption = previousRoute?.caption;
    const nestedRoutes = currentRoute.routes;
    const nextRouteKey = Object.keys(nestedRoutes)[0];

    const { value: nextRoute, caption: nextCaption } =
      nestedRoutes[nextRouteKey] || {};

    const trailingSlash = path[path.length - 1] === '/';
    const next = trailingSlash ? nextRoute : `${route}/${nextRoute}`;

    return setNavigation({
      next,
      nextCaption,
      back: previousRoute?.value,
      backCaption,
    });
  }
  // If route is in itself a child/nested route
  if (BravelyData('demoSurveyDisabled') || parentIdx !== 0) {
    if (!isLastRoute) {
      const nextRoute = peerRoutes[idx + 1]?.value;
      let previousRoute = peerRoutes[idx - 1]?.value;

      // If route is first of children
      if (idx === 0) {
        previousRoute =
          '/' + parentRoute?.parentSlug + '/' + parentRoute?.value;
      }

      const backCaption = previousRoute?.caption;

      return setNavigation({
        next: nextRoute,
        nextCaption: map[nextRoute]?.caption,
        back: previousRoute,
        backCaption,
        currentPageNumber: idx + 1,
      });
    } else {
      const next = parentIdx + 1;
      const previousRoute = peerRoutes[idx - 1];
      const ancestor = map[parentRoute.parentSlug];
      const nextRoute = Object.values(ancestor?.routes || {})[next]?.value;
      const slugToRemove = `${parentSlug}/${currentRoute.value}`;
      const nextAncestorRoute = path.slice(0, -slugToRemove.length) + nextRoute;

      return setNavigation({
        next: nextAncestorRoute,
        nextCaption: map[nextRoute]?.caption,
        back: previousRoute?.value,
        currentPageNumber: idx + 1,
      });
    }
  } else {
    const nextRoute = (Object.values(peerRoutes) || [])[idx + 1]?.value;
    const previousRoute = peerRoutes[idx - 1] || {};
    const previousRouteRoutes = previousRoute?.routes || [];
    const isAfterNestedRoutes = Boolean(previousRouteRoutes.length);

    let back = previousRoute?.value;
    if (isAfterNestedRoutes) {
      back =
        previousRoute.value +
        '/' +
        previousRouteRoutes[previousRouteRoutes.length - 1].value;
    } else if (idx === 0) {
      back = '/' + parentRoute?.value;
    }

    return setNavigation({
      next: nextRoute,
      nextCaption: map[nextRoute]?.caption,
      back,
      backCaption: parentRoute?.caption,
    });
  }
};
