import React from 'react';
import cx from 'classnames';

import { Mobile } from '../../../../utils/responsive';

import styles from './styles.module';

const PrimaryButton = ({ floating, stickToBottom = true, ...props }) => {
  const className = cx(styles.Button, props.className, {
    [styles.floating]: floating,
  });

  const button = (
    <button {...props} className={className}>
      {props.children}
    </button>
  );

  if (floating) {
    return (
      <React.Fragment>
        <div className={styles.floatingContainer}>{button}</div>
        {/* slightly hacky: a floating button just arbitrarily adds 70px
          of padding to the end of the component it's rendered in, to
          prevent button from covering content in component. this may not work
          for pages with more content after the PrimaryButton, but is okay for
          how we're using it here. may be good to some day instead add a class
          to the top-level container to add padding if this is present. */}
        <div className={styles.floatingPadding} />
      </React.Fragment>
    );
  } else {
    return (
      <Mobile>
        {(matches) =>
          matches ? (
            <div
              className={cx({
                [styles.stickToBottomContainer]: stickToBottom,
              })}
            >
              {button}
            </div>
          ) : (
            button
          )
        }
      </Mobile>
    );
  }
};

export default PrimaryButton;
