// Email Validation

// Only one email address
// Valid email address format
// Only org email, so has to have org email in the value.
// Show character limit (200 max)

import { REGEX } from 'portal/pages/NeedsAndDemography/helpers';
import BravelyData from 'utils/data';

export const MSG_CHAR_LIMIT = 200;

export const emailValidation = (val = '', setter, t = (val) => val) => {
  const email = val.trim();
  const workEmail = BravelyData('workEmail') || '';
  const isOwnEmail = workEmail.trim() === email;

  // Validation messages
  const VALIDATION_MESSAGES = {
    invalidEmailFormat: t('The email format is not valid'),
    isBlank: t('Email address cannot be blank'),
    isOwnEmail: t("Please enter an email that's not your own"),
  };

  // Validation checks
  const isBlank = email.length == 0;
  if (isBlank) return setter(VALIDATION_MESSAGES['isBlank']);
  const invalidEmailFormat = !email.match(REGEX.VALID_EMAIL_ADDRESS);
  if (invalidEmailFormat)
    return setter(VALIDATION_MESSAGES['invalidEmailFormat']);
  if (isOwnEmail) return setter(VALIDATION_MESSAGES['isOwnEmail']);
  setter(undefined);
};

export const msgValidation = (val = '', setter, t = (val) => val) => {
  const VALIDATION_MESSAGES = {
    exceedsCharLimit: t('You have exceeded the character limit') + ' (200)',
    isEmpty: t('This field is required'),
  };
  const msg = val.trim();
  const exceedsCharLimit = msg.length > MSG_CHAR_LIMIT;
  const isEmpty = msg.length === 0;
  if (isEmpty) return setter(VALIDATION_MESSAGES['isEmpty']);
  if (exceedsCharLimit) return setter(VALIDATION_MESSAGES['exceedsCharLimit']);
  setter(undefined);
};
