import homeIcon from '../../../../../assets/images/booking/home.svg';
import userIcon from '../../../../../assets/images/booking/person.svg';
import reportIcon1 from '../../../../../assets/images/organization/reportIcon1.svg';
import reportIcon2 from '../../../../../assets/images/organization/reportIcon2.svg';
import reportIcon3 from '../../../../../assets/images/organization/reportIcon3.svg';

import BravelyData from '../../../../utils/data';

const DEFAULT_NAV_DATA = {
  links: {},
  loggedOut: true,
  logoUrl: '/',
  theme: 'light',
};

const NAV_DATA = BravelyData('navigation', DEFAULT_NAV_DATA);

const NAV_ICONS = {
  homeIcon: homeIcon,
  userIcon: userIcon,
  reportIcon1: reportIcon1,
  reportIcon2: reportIcon2,
  reportIcon3: reportIcon3,
};

function prepareLinks(links, events = {}) {
  return Object.keys(links).map((key) => {
    const link = links[key];
    if (link.dropdown == true) {
      return prepareDropdown(key, link, events);
    } else if (link.divider == true) {
      return prepareDivider(key, link);
    } else {
      return prepareLink(key, link, events[key]);
    }
  });
}

function prepareDivider(key, divider) {
  return {
    divider: true,
    key: key,
    label: divider.label,
  };
}

function prepareDropdown(key, dropdown, events = {}) {
  return {
    dropdown: true,
    key: key,
    label: dropdown.label,
    links: prepareLinks(dropdown.links, events),
  };
}

function prepareLink(key, link, onClickEvent = null) {
  return {
    key: key,
    label: link.label,
    url: link.url,
    target: link.target || null,
    icon: NAV_ICONS[link.icon] || null, // change 'icon' string into icon var without eval()
    onClick: onClickEvent,
    id: link.id,
  };
}

export const navConfig = function(events = {}) {
  if (NAV_DATA.disable == true) {
    return { disable: true };
  }

  return {
    loggedOut: NAV_DATA.loggedOut,
    logoUrl: NAV_DATA.logoUrl,
    theme: NAV_DATA.theme,
    links: prepareLinks(NAV_DATA.links, events),
  };
};
