import React, { useState, useEffect } from 'react';

// Dependencies
import { useTranslation } from 'react-i18next';

// Shared Components
import Modal from 'shared/components/Modal';
import airbrake from 'utils/airbrake';
import { fetchOptions } from 'utils/api/helpers';
import { mixpanelEvents } from 'utils/mixpanelHelpers';
import endpoints from 'utils/api/endpoints';
import Cookies from 'js-cookie';

// Styles
import './styles.scss';

// Helpers
import { emailValidation, msgValidation } from './helpers';

// Helper Components
import Body from './components/Body';

// Constants
const DEFAULT_MSG =
  'Bravely coaching has been a gamechanger for my life at work. I think it could make a difference in yours, too!';

const defaultStyleOverrides = {
  height: 'auto',
  width: 'auto',
  margin: '12px 44px 12px 36px',
  inset: 'unset',
  position: 'relative',
};

const ReferACoWorkerModal = ({
  showModal,
  setShowModal,
  styleOverrides = {},
}) => {
  const [emailFocus, setEmailFocus] = useState(false);
  const [msg, setMsg] = useState(DEFAULT_MSG);
  const [email, setEmail] = useState('');
  const [btnText, setBtnText] = useState('Send');
  const [changesMade, setChangesMade] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailValidationMessage, setEmailValidationMessage] = useState();
  const [msgValidationMessage, setMsgValidationMessage] = useState();
  const [hasValidated, setHasValidated] = useState(false);
  const [serverError, setServerError] = useState(false);

  const { t } = useTranslation();

  const validate = () => {
    emailValidation(email, setEmailValidationMessage, t);
    msgValidation(msg, setMsgValidationMessage, t);
    setHasValidated(true);
  };

  useEffect(() => {
    if (hasValidated) {
      handleSend();
    }
  }, [emailValidationMessage, msgValidationMessage]);

  const trackViewForm = () =>
    endpoints.trackEvent({
      eventTitle: mixpanelEvents.referACoWorker.VIEW_FORM,
      otherProperties: { location: 'Modal' },
    });

  useEffect(() => {
    showModal && trackViewForm();
  }, [showModal]);

  const resetButton = () => {
    setEmailValidationMessage('');
    setMsgValidationMessage('');
    setHasValidated(false);
    setServerError(false);
    if (!changesMade) {
      setChangesMade(true);
      setBtnText('Send');
    }
  };

  const trackReferral = (encodedId) => {
    if (!encodedId) return;

    // UTM Tracking
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'Submitted Referral',
        referredEmployee: encodedId,
      });
    }

    // Mixpanel Tracking
    endpoints.trackEvent({
      eventTitle: mixpanelEvents.referACoWorker.SUBMIT,
      otherProperties: { location: 'Modal', referredEmployee: encodedId },
    });
  };

  const handleSend = async () => {
    try {
      const msgSent = btnText === 'Sent!';
      const error = emailValidationMessage || msgValidationMessage;
      if (error || msgSent) return;
      setIsSubmitting(true);
      const payload = {
        body: JSON.stringify({
          email: email?.trim(),
          message: msg?.trim(),
          iterable_email_campaign_id: Cookies.get('iterableEmailCampaignId'),
          iterable_template_id: Cookies.get('iterableTemplateId'),
        }),
      };
      const response = await fetch('/v1/employee/users/refer', {
        ...fetchOptions('POST'),
        ...payload,
      }).then((res) => res.json());

      if (response?.success == false) {
        setEmailValidationMessage(t(response.msg));
      } else {
        trackReferral(response?.referred?.encoded_id);
        setBtnText('Sent!');
      }
    } catch (err) {
      airbrake.notify({
        error: err,
        context: { severity: 'warning' },
      });
      setServerError(true);
    }

    setIsSubmitting(false);
    setChangesMade(false);
  };

  const resetModal = () => {
    setShowModal(false);
    setEmail('');
    setMsg(DEFAULT_MSG);
    resetButton();
  };

  return (
    <Modal
      show={showModal}
      closeFunction={resetModal}
      bodyComponent={
        <Body
          email={email}
          setEmail={setEmail}
          emailFocus={emailFocus}
          setEmailFocus={setEmailFocus}
          msg={msg}
          setMsg={setMsg}
          emailValidationMessage={emailValidationMessage}
          msgValidationMessage={msgValidationMessage}
          validate={validate}
          btnText={btnText}
          isSubmitting={isSubmitting}
          resetButton={resetButton}
          serverError={serverError}
          t={t}
        />
      }
      showFooter={false}
      styleOverrides={{ ...defaultStyleOverrides, ...styleOverrides }}
    />
  );
};

export default ReferACoWorkerModal;
